<template>
  <div v-if="page.length">
    <div class="bg-f7 tc pt50">
      <div
        class="f32 mt50"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="zoom-in"
      >
        {{ page[0].t1 }}
      </div>
      <div
        class="f24 mtb20"
        style="font-weight:lighter"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        {{ page[0].t2 }}
      </div>
      <img
        :src="isMobile ? page[0].img_m : page[0].img"
        alt=""
        :style="isMobile ? 'width:100%' : 'width:1150px;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
      <div
        class="ptb50 bg-fff"
        :class="isMobile ? 'plr20' : ''"
      >
        <div
          class="m-auto bd bb bd-ccc"
          :class="isMobile ? 'f28 mb20 pb20' : 'f40 mb30 pb30'"
          :style="isMobile ? 'width:100%' : 'width:1000px;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          {{ page[0].t3 }}
        </div>
        <div
          style="font-weight:lighter"
          :class="isMobile ? 'f16 tl' : 'f18'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div>{{ page[0].t4 }}</div>
          <div>{{ page[0].t5 }}</div>
        </div>
      </div>
    </div>
    <div
      class="flex-center"
      :class="isMobile ? 'flex-wrap ptb40' : 'ptb30'"
      style="background:#F1F9FE"
    >
      <div
        v-for="item in page[0].d"
        :key="item.t"
        class="bg-fff br4 mlr10 tc ptb20 plr15"
        :style="isMobile ? 'width:150px;height:150px;margin-bottom:15px;' : 'width:200px;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="flip-left"
        data-aos-delay="100"
      >
        <img
          :src="item.icon"
          style="width:45px;"
          class="mb10"
        >
        <p>{{ item.t }}</p>
      </div>
    </div>

    <div
      class=""
      :class="isMobile ? 'tl' : 'tc pb50'"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px; margin-top:30px; text-align: center;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-html="page[1].t1.replace('\n', '<br>')"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="zoom-in"
        data-aos-delay="100"
      />
      <div
        class="relative z1"
        style="width: 30px;height: 2px;background: #ed7989; "
        :style="isMobile ? 'margin:20px auto 0 auto;' : 'margin:0 auto;'"
      />
      <div
        v-for="(item,i) in page[1].d"
        :key="i"
        class="tl mt20"
        :class="isMobile ? '' : 'flex-center m-auto'"
        :style="isMobile ? '' : 'background:#F6F6F6;width:fit-content;'"
      >
        <img
          v-if="!isMobile && i % 2 !== 0"
          :src="item.img"
          alt=""
          style="height:310px;"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-right"
          data-aos-delay="100"
        >
        <div
          :class="isMobile ? 'p20' : 'p40'"
          :style="isMobile ? '' : 'width:720px;'"
        >
          <div class="flex items-center bd bb bd-ccc mb20 pb20">
            <img
              :src="item.icon"
              :style="isMobile ? 'width:25px' : 'width:50px'"
              alt=""
            >
            <span :class="isMobile ? 'f20' : 'f40'">{{ item.t }}</span>
          </div>
          <div
            class="f20 c-666"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="fade-up"
          >
            {{ item.d }}
          </div>
        </div>
        <img
          v-if="isMobile || !isMobile && i % 2 === 0"
          :src="isMobile ? item.img_m : item.img"
          alt=""
          :style="isMobile ? 'width:100%' : 'height:310px;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-left"
        >
      </div>
    </div>

    <div
      class="tc pb50"
      :style="isMobile ? '' : 'background:#FAFCFF'"
    >
      <img
        :src="isMobile ? page[2].img_m : page[2].img"
        alt=""
        class="width-100"
      >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 0px 0 10px 0;'"
        v-html="isMobile ? page[2].t1.split(' ').join('<br>') : page[2].t1"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      />
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />
      <div
        class="mt50"
        :class="isMobile ? 'plr15' : 'flex-center'"
      >
        <div
          v-for="(item,i) in page[2].d"
          :key="i"
          :style="isMobile ? 'width:100%;background:#F1F9FE' : 'width:340px'"
          class="br10 bg-fff pb20"
          :class="isMobile ? 'mb20' : 'mlr30'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          <img
            :src="item.img"
            alt=""
            class="width-100"
          >
          <div class="pt40 plr20 tl">
            <div
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-up"
              class="f24 bd bb bd-ccc mb15 pb15"
            >
              {{ item.t }}
            </div>
            <div
              class="f18"
              style="font-weight:lighter"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-up"
            >
              {{ item.d }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      page: []
    }
  },

  async created () {
    const { data } = await this.fetch('/huiyuan.json')
    this.page = data
  },

  methods: {
  }
}
</script>
